<template>
  <main class="page__content">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <h2>Components</h2>
      <div class="mt-5">
        <p>Accordion</p>
        <Accordion
          v-bind:title="content_header.title"
          v-bind:subTitle="content_header.subTitle"
        >
        </Accordion>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Components",
  data: function () {
    return {
      content_header: {
        title: "Organisation Details",
        subTitle:
          "Set up Mambu with all the details for your organization. Define your institution details and contact information.",
      },
    };
  },
};
</script>
